/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
import "../application.sass";
import "controllers";
import { initAll } from "navody-digital-frontend";

const deleteOriginalElement = (domElement) => {
  if (domElement) {
    domElement?.remove();
  }
};

document.addEventListener("turbolinks:load", function () {
  // Initialize GovUK/Navody-frontend Javascript
  initAll();
});

document.addEventListener("turbolinks:before-cache", function () {
  // HOTFIX: In the case of duplicated element causing original element to not work.
  // Original element need to be removed from DOM before cache-event and before loading new one.

  deleteOriginalElement(document.querySelector(".govuk-accordion__controls"));
});
